export const YesNo = [
  { value: 1, text: '有效' },
  { value: 2, text: '无效' }
]
export const UserRole = [{ value: 1, text: '普通' }]
export const UserType = [
  { value: 1, text: '普通' },
  { value: 2, text: '系统' }
]
export const Method = [
  { value: 'report', text: '数据上报' },
  { value: 'report_reply', text: '数据上报回复' },
  { value: 'action', text: '命令下发' },
  { value: 'action_reply', text: '命令下发回复' },
  { value: 'event', text: '事件上报' },
  { value: 'event_reply', text: '事件上报回复' }
]
export const Action = [
  { value: 'control', text: '设备控制' },
  { value: 'report', text: '获取设备数据' },
  { value: 'job', text: '定时任务下发' },
  { value: 'config', text: '参数配置' },
  { value: 'reboot', text: '重启设备' },
  { value: 'ntp', text: '校时' },
  { value: 'open_debug', text: '打开调试' },
  { value: 'close_debug', text: '关闭调试' }
]
export const Event = [{ value: 'log', text: '日志' }]
export const TerminalLogType = [
  { value: 1, text: '信息' },
  { value: 2, text: '警告' },
  { value: 3, text: '错误' }
]

export const DeliverStatus = [
  { value: 1, text: '未投递' },
  { value: 2, text: '成功' },
  { value: 3, text: '失败' },
  { value: 4, text: '重试中' }
]
export const SysLogType = [
  { value: 1, text: '调试' },
  { value: 2, text: '轨迹' },
  { value: 3, text: '错误' },
  { value: 4, text: '异常' }
]
export const Direction = [
  { value: 1, text: '上行' },
  { value: 2, text: '下行' },
  { value: 3, text: '双向' }
]
export const MessageStatus = [
  { value: 1, text: '进行中' },
  { value: 2, text: '完成' },
  { value: 3, text: '失败' }
]
export const TerminalStatus = [
  { value: 1, text: '未启用' },
  { value: 2, text: '配置中' },
  { value: 3, text: '正常' },
  { value: 4, text: '离线' },
  { value: 5, text: '无效' }
]
export const ProductStatus = [
  { value: 1, text: '上架' },
  { value: 2, text: '下架' }
]
export const Performance = [
  { value: 1, text: '锐利' },
  { value: 2, text: '平滑' }
]
export const DataValueType = [
  { value: 1, text: '模拟量' },
  { value: 2, text: '开关量' }
]
export const Chart = [
  { value: 1, text: '曲线图' },
  { value: 2, text: '仪表图' },
  { value: 3, text: '开关图' }
]
export const DataStatus = [
  { value: 1, text: '有效' },
  { value: 2, text: '无效' }
]
export const PowerKind = [
  { value: 1, text: '低' },
  { value: 2, text: '高' }
]

export const MessageDirection = [
  { value: 1, text: '上行请求' },
  { value: 2, text: '下行回复' },
  { value: 3, text: '下行命令' },
  { value: 4, text: '上行结果' }
]
export const CommandStatus = [
  { value: 1, text: '准备' },
  { value: 2, text: '执行中' },
  { value: 3, text: '成功' },
  { value: 4, text: '失败' },
  { value: 5, text: '超时' }
]

export const EverySelect = [
  { value: null, text: '原始数据' },
  { value: '10s', text: '10秒平均' },
  { value: '30s', text: '30秒平均' },
  { value: '1m', text: '1分钟平均' },
  { value: '5m', text: '5分钟平均' },
  { value: '10m', text: '10分钟平均' },
  { value: '30m', text: '半小时平均' },
  { value: '60m', text: '1小时平均' }
]
export const Factory = [
  { value: 1, text: '容讯' },
  { value: 2, text: '深圳广合霖科技' }
]

export const SwitchJobStatus = [
  { value: 1, text: '已创建' },
  { value: 2, text: '同步中' },
  { value: 3, text: '已同步' },
  { value: 4, text: '同步失败' },
  { value: 5, text: '已取消' }
]
export const SwitchPlanStatus = [
  {
    value: true,
    text: '已启用'
  },
  {
    value: false,
    text: '已禁用'
  }
]

export const valueToStr = (dict, value) => {
  if (!dict || !dict.length) {
    alert(`字典：${dict}不存在！`)
    return ''
  }
  // let v=parseInt(value);
  let v = value
  for (let i = 0; i < dict.length; i++) {
    if (dict[i].value == v) return dict[i].text
  }
  return '未知'
}
