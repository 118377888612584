<template>
  <el-dialog
    title="密码修改"
    :visible.sync="visible"
    :lock-scroll="false"
    :close-on-click-modal="false"
    width="600px"
  >
    <el-card shadow="never">
      <el-form :model="form" :rules="rules" ref="form" label-position="right" label-width="120px">
        <el-form-item prop="OldPass" label="原密码">
          <el-input type="password" v-model="form.OldPass" auto-complete="off" placeholder="原密码"></el-input>
        </el-form-item>
        <el-form-item prop="NewPass" label="新密码">
          <el-input type="password" v-model="form.NewPass" auto-complete="off" placeholder="新密码"></el-input>
        </el-form-item>
        <el-form-item prop="ConfirmPass" label="确认新密码">
          <el-input
            type="password"
            v-model="form.ConfirmPass"
            auto-complete="off"
            placeholder="确认新密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="VerifyCode" label="验证码">
          <iptValidCode v-model="form.VerifyCode" ref="validCodeInput">
            <template slot="append">
              <span style="padding-left:10px">计算结果</span>
            </template>
          </iptValidCode>
        </el-form-item>
      </el-form>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false" v-space>取 消</el-button>
      <el-button type="primary" @click="onSave" v-space>确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import iptValidCode from "@/components/form/iptValidCode";
export default {
  data() {
    return {
      form: {
        OldPass: "",
        NewPass: "",
        ConfirmPass: "",
        VerifyCode: ""
      },
      visible: false,
      rules: {
        OldPass: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur"
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          }
        ],
        NewPass: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur"
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          }
        ],
        ConfirmPass: [
          {
            required: true,
            message: "请再次输入新密码",
            trigger: "blur"
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          }
        ],
        VerifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.form = {};
      if (this.$refs.validCodeInput) this.$refs.validCodeInput.refresh();
    },
    onSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.NewPass != this.form.ConfirmPass) {
            this.$alert("两次密码不一致！", { type: "error" });
            return;
          }
          let evidence = this.$refs.validCodeInput.getEvidence();
          this.form.Evidence=evidence;
          this.$secret
            .changePass(
              this.form
            )
            .then(() => {
              this.$alert("密码已成功修改！", { type: "info" });
              this.$emit("confirm");
              this.visible = false;
              window.location.href = "/login";
            })
            .catch(err => {
              this.$refs.validCodeInput.refresh();
              this.$handleApiError(err);
            });
        }
      });
    }
  },
  components: {
    iptValidCode
  }
};
</script>