<template>
	<el-input auto-complete="off" placeholder="验证码" v-model="validCode" @change="onChange" class='yzm'>
		<template slot="append">
			<img :src='validCodeImgUrl' @click="refresh" style="width:80px;height:30px" />
			<el-button @click="refresh">换一个</el-button>
		</template>
		<template slot="prepend" v-if="showPrepend">
			<slot name="prepend" />
		</template>
	</el-input>
</template>
<script>
export default {
    props: { showPrepend: Boolean },
    data() {
        return {
            validCodeImgUrl: '',
            evidence: '',
			validCode:''
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            let { url, evidence } = this.$secret.getValidCodeImgUrl();
            this.validCodeImgUrl = url;
            this.evidence = evidence;
        },
        onChange(value) {
            this.$emit('input', value);
        },
        getEvidence() {
            return this.evidence;
        }
    }
};
</script>

<style>
.yzm .el-input-group__append {
    border: none;
    text-align: right;
}
.yzm .el-input-group__append img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
</style>