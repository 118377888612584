import { http, APIRoot, doExec, local } from './request'
import JSON5 from 'json5'
import md5 from '../utils/md5'
import XDate from 'xdate'
import qs from 'qs'
import { newGuid } from '../utils/tools'
import des from '../utils/des'

export const getValidCodeImgUrl = () => {
	let evidence = newGuid();
	let url = APIRoot + 'Verify/Image?evidence=' + evidence;
	return { url, evidence };
};
export const sendResetPassCode = (account) => {
	let evidence = newGuid();
	let info = {};
	info.Account = account;
	info.Evidence = evidence;
	return http.post('Account/SendForgetPassSMS', info).then((res) => {
		return evidence;
	});
};

const createKey = (pp, pass) => {
	var keystr = md5(pp + pass);
	return keystr;
};
const getCommKey = (code) => {
	return (code + md5(code)).substr(0, 8);
}
export const login = (info) => {
	let body = {};
	body.Evidence = info.Evidence;
	body.Account = info.Account;
	body.VerifyCode = md5(info.VerifyCode.toUpperCase());
	let ts = new XDate().toString('yyyyMMddHHmmss');
	body.TS = ts;
	let str = qs.stringify(body, { arrayFormat: 'brackets' });
	let signStr = md5(str + info.Pass);
	str = str + '&Sign=' + signStr;

	return http.post('Account/Login?' + str, {}).then((res) => {
		localStorage.setItem('UserID', res.data.UserID);
		localStorage.setItem('PP', res.data.PP);
		localStorage.setItem('UserName', res.data.UserName);
		let key = createKey(res.data.PP, info.Pass);
		key = des.encrypt(key, local());
		localStorage.setItem('Key', key);
		return true;
	});
};
export const logout = () => {
	localStorage.removeItem('Key');
	let pp = localStorage.getItem('PP');
	return http.get('Account/Logout?pp=' + pp).then(() => {
		localStorage.clear();
	});
};

export const changePass = (form) => {
	let verifyCode = form.VerifyCode.toUpperCase();
	let info = {
		Evidence: form.Evidence,
		VerifyCode: md5(verifyCode),
		OldPass: des.encrypt(form.OldPass, getCommKey(verifyCode)),
		NewPass: des.encrypt(form.NewPass, getCommKey(verifyCode))
	}
	return doExec('Account/ChangePass', null, info, 'post')
		.then((res) => {
			localStorage.setItem('PP', res.PP);
			localStorage.setItem('UserName', res.UserName);
			let key = createKey(res.PP, info.NewPass);
			localStorage.setItem('Key', des.encrypt(key, local()));
			localStorage.setItem('LastActiveTime', new XDate());
		});
};
export const IsValidImageCode = (evidence, verifyCode) => {
	let info = {};
	info.Evidence = evidence;
	info.VerifyCode = md5(verifyCode.toUpperCase());
	return http.post('Verify/IsRightCode', info).then((res) => { return res.data });
};
export const resetPass = (form) => {
	let info = {};
	info.Pass = des.encrypt(form.NewPass, getCommKey(form.SMSCode));
	info.Account = form.Account;
	info.SMSCode = md5(form.SMSCode);
	info.Evidence = form.Evidence;
	return http.post('Account/ResetPass', info);
};

