import { Message } from 'element-ui';

export default {
	bind(el, binding) {
		el.$value = binding.value
		el.handler = () => {
			const textarea = document.createElement('textarea')
			textarea.readOnly = 'readonly'
			textarea.style.position = 'absolute'
			textarea.style.left = '-9999px'
			textarea.value = el.$value
			document.body.appendChild(textarea)
			textarea.select()
			textarea.setSelectionRange(0, textarea.value.length)
			const result = document.execCommand('Copy')
			if (result) {
				Message.success("复制成功")
			}
			document.body.removeChild(textarea)
		}
		el.addEventListener('click', el.handler)
	},
	// 当传进来的值更新的时候触发
  componentUpdated(el, { value }) {
    el.$value = value;
  },
	unbind(el){
		el.removeEventListener('click', el.handler)
	}
}
