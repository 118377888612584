<template>
  <div>
    <div class="top">
      <appHeader/>
    </div>
    <div class="left" :style="{width:navBarWidth}">
      <navBar/>
    </div>
    <div class="main" :style="{left:mainLeft}">
      <div class="tag"><crumb/></div>
      <div class="content"> <appMain/></div>
    </div>
  </div>
</template>
<script>
import dlgChangePass from "@/view/account/dlgChangePass";
import crumb from "./crumb";
import appHeader from "./appHeader";
import appMain from "./appMain";
import TagsView from "./TagsView";
import navBar from "./navBar";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters(["sidebar"]),
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
    navBarWidth() {
      return this.sidebar.opened ? "180px" : "64px";
    },
    mainLeft(){
      return this.sidebar.opened ? "180px" : "64px";
    }
  },
  mixins: [ResizeMixin],
  methods: {},
  components: { appHeader, crumb, appMain, navBar, TagsView }
};
</script>
<style>
.top{
    left:0;
    top:0;
    width: 100%;
    height: 60px;
    position: absolute;
    background-color:#243c44;    
}
.left {
    position: absolute;
    bottom:0;
    top:60px;
    left:0;
    bottom:0px;
    width:180px;
    background-color:#243c44;
}

.main{
    position: absolute;
    left:180px;
    top: 60px;
    bottom: 0px;
    right:0px;
    
}
.tag{
  position: absolute;
  height:40px;
  width:100%;
}
.content{
  position: absolute;
  width:100%;
  top:44px;
  bottom:0px;
  overflow: auto;
}

</style>



