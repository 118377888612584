<template>
  <el-scrollbar class="scrollbar-wrapper">
    <el-menu :router="true" :default-openeds="defaultMenuOpen" :default-active="$route.path" style="padding:0;border-width:0" :collapse="isCollapse" background-color="#243c44" text-color="#ddd" active-text-color="#ffd04b">
      <el-submenu :index="item.path" v-for="item in filterRoutes" :key="item.path">
        <template slot="title">
          <svg-icon :iconClass="item.meta.icon" />
          <span style="padding-left:4px">{{item.meta.title}}</span>
        </template>
        <el-menu-item :index="item.path+'/'+subitem.path" v-for="subitem in item.children" :key="subitem.path">
          <svg-icon :iconClass="subitem.meta.icon" />
          <span style="padding-left:4px" slot="title">{{subitem.meta.title}}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-scrollbar>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      actions:[]
    }
  },
  computed: {
    ...mapGetters(['permission_routers', 'sidebar']),
    isCollapse() {
      return !this.sidebar.opened
    },
    defaultMenuOpen() {
      let arr = ['/' + this.$route.path.split('/')[1]]
      return arr
    },
    filterRoutes() {
      if(!this.actions || this.actions.length==0) return [];
      let arr=[];
      this.$router.options.routes.forEach(parent => {
        if (!parent.children || parent.hiddenMenu) {
          return
        }
        let children = parent.children.filter(child => {
          return this.actions.some(action => {
            return (
              action.Method == 'GET' &&
              action.Url.indexOf('#'+parent.path + '/' + child.path) != -1
            )
          })
        })
        if(children.length==0)
          return
        parent.children=children;
        arr.push(parent);        
      })
      return arr;
    }
  },
  mounted(){
    let userID=localStorage.getItem('UserID');
    this.$api.User.Action.List(userID).then(res=>{
      this.actions=res;
    }).catch(this.$handleApiError)
  }
}
</script>
<style lang="less">
.scrollbar-wrapper {
  height: 100%;
  width: 100%;
  background-color: #243c44;
  overflow-x: hidden;
}
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-submenu .el-menu-item {
  min-width: 160px;
}
</style>



