import { doExec } from '@/api/request'
export default {
  Analysis: {
    Quota() {
      return doExec('Analysis/Quota', {}, {}, 'GET')
    }
  },
  Msg: {
    /*消息搜索*/
    Search(condition) {
      return doExec('Msg/Search', condition, {}, 'GET')
    }
  },
  SensorType: {
    /*获取传感器类型信息*/
    Get(typeCode) {
      return doExec('SensorType', { typeCode }, {}, 'GET')
    } /*获取所有传感器类型*/,
    List() {
      return doExec('SensorType/List', {}, {}, 'GET')
    } /*搜索传感器类型*/,
    Search(condition) {
      return doExec('SensorType/Search', condition, {}, 'GET')
    } /*增加传感器类型*/,
    Add(form) {
      return doExec('SensorType', {}, form, 'POST')
    } /*修改传感器类型信息*/,
    Modify(typeCode, form) {
      return doExec('SensorType', { typeCode }, form, 'PUT')
    } /*删除传感器类型*/,
    Delete(typeCode) {
      return doExec('SensorType', { typeCode }, {}, 'DELETE')
    }
  },
  DataType: {
    /*获取所有数据类型*/
    List() {
      return doExec('DataType/List', {}, {}, 'GET')
    } /*搜索数据类型*/,
    Search(condition) {
      return doExec('DataType/Search', condition, {}, 'GET')
    } /*获取数据类型信息*/,
    Get(typeCode) {
      return doExec('DataType', { typeCode }, {}, 'GET')
    } /*增加传感器类型*/,
    Add(form) {
      return doExec('DataType', {}, form, 'POST')
    } /*修改数据类型信息*/,
    Modify(typeCode, form) {
      return doExec('DataType', { typeCode }, form, 'PUT')
    } /*删除数据类型*/,
    Delete(typeCode) {
      return doExec('DataType', { typeCode }, {}, 'DELETE')
    }
  },
  DeviceType: {
    /*获取设备类型信息*/
    Get(typeCode) {
      return doExec('DeviceType', { typeCode }, {}, 'GET')
    } /*获取所有设备类型*/,
    List() {
      return doExec('DeviceType/List', {}, {}, 'GET')
    } /*搜索设备类型*/,
    Search(condition) {
      return doExec('DeviceType/Search', condition, {}, 'GET')
    } /*增加设备类型*/,
    Add(form) {
      return doExec('DeviceType', {}, form, 'POST')
    } /*修改设备类型信息*/,
    Modify(typeCode, form) {
      return doExec('DeviceType', { typeCode }, form, 'PUT')
    } /*删除设备类型*/,
    Delete(typeCode) {
      return doExec('DeviceType', { typeCode }, {}, 'DELETE')
    }
  },
  MsgType: {
    /*获取消息类型信息*/
    Get(typeCode) {
      return doExec('MsgType', { typeCode }, {}, 'GET')
    } /*获取指定类型的消息类型信息*/,
    List(kind) {
      return doExec('MsgType/List', { kind }, {}, 'GET')
    } /*增加消息类型*/,
    Add(form) {
      return doExec('MsgType', {}, { form }, 'POST')
    } /*修改消息类型信息*/,
    Modify(typeCode, form) {
      return doExec('MsgType', { typeCode }, form, 'PUT')
    } /*禁用消息类型*/,
    Disable(typeCode) {
      return doExec('MsgType/Disable', { typeCode }, {}, 'POST')
    } /*启用消息类型*/,
    Enable(typeCode) {
      return doExec('MsgType/Enable', { typeCode }, {}, 'POST')
    },
    Search(condition) {
      return doExec('MsgType/Search', condition, {}, 'GET')
    }
  },
  TerminalLog: {
    /*搜索终端日志*/
    Search(condition) {
      return doExec('TerminalLog/Search', condition, {}, 'GET')
    } /*清除指定天之前的记录*/,
    ClearBeforeDays(dayBefore) {
      return doExec('TerminalLog/ClearBeforeDays', { dayBefore }, {}, 'POST')
    }
  },
  Tentacle: {
    /*获取数据配置项*/
    Get(id,includes) {
      return doExec('Tentacle', { id,includes }, {}, 'GET')
    } /*增加传感器数据种类配置*/,
    Add(form) {
      return doExec('Tentacle', {}, form, 'POST')
    } /*修改传感器数据种类配置信息*/,
    Modify(id, form) {
      return doExec('Tentacle', { id }, form, 'PUT')
    } /*删除传感器数据种类配置信息*/,
    Delete(id) {
      return doExec('Tentacle', { id }, {}, 'DELETE')
    },
    /*搜索*/
    Search(condition) {
      return doExec('Tentacle/Search', condition, {}, 'GET')
    },
  },
  User: {
    Get(id) {
      return doExec('User', { id }, {}, 'GET')
    },
    Add(form) {
      return doExec('User', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('User', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('User', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('User/Search', condition, {}, 'GET')
    },
    Deblocking(id) {
      return doExec('User/Deblocking', { id }, {}, 'POST')
    },
    ResetPass(id) {
      return doExec('User/ResetPass', { id }, {}, 'POST')
    },
    List(role) {
      return doExec('User/List', { role }, {}, 'GET')
    },
    Right: {
      List(id) {
        return doExec('User/Right/List', { id }, {}, 'GET')
      },
      Update(id, rights) {
        return doExec('User/Right/Update', { id }, rights, 'PUT')
      }
    },
    Action: {
      List(id) {
        return doExec('User/Action/List', { id }, {}, 'GET')
      }
    }
  },
  Right: {
    Search(condition) {
      return doExec('Right/Search', condition, {}, 'GET')
    },
    Add(form) {
      return doExec('Right', {}, form, 'POST')
    },
    Modify(rightCode, form) {
      return doExec('Right', { rightCode }, form, 'PUT')
    },
    Delete(rightCode) {
      return doExec('Right', { rightCode }, {}, 'DELETE')
    },
    Get(rightCode) {
      return doExec('Right', { rightCode }, {}, 'GET')
    },
    List() {
      return doExec('Right/List', {}, {}, 'GET')
    },
    Action: {
      Add(form) {
        return doExec('Right/Action', {}, form, 'POST')
      },
      Modify(actionID, form) {
        return doExec('Right/Action', { actionID }, form, 'PUT')
      },
      Delete(actionID) {
        return doExec('Right/Action', { actionID }, {}, 'DELETE')
      },
      List(rightCode) {
        return doExec('Right/Action/List', { rightCode }, {}, 'GET')
      },
      Get(actionID) {
        return doExec('Right/Action', { actionID }, {}, 'GET')
      }
    }
  },

  Log: {
    /*日志搜索*/
    Search(condition) {
      return doExec('Log/Search', condition, {}, 'GET')
    } /*清理日志*/,
    Clear(atDate) {
      return doExec('Log/Clear', { atDate }, {}, 'POST')
    }
  },
  Command: {
    /*获取命令*/
    Get(id) {
      return doExec('Command/Get', { id }, {}, 'GET')
    } /*添加命令*/,
    Add(form) {
      return doExec('Command', {}, form, 'POST')
    } /*重发命令*/,
    ReSend(id) {
      return doExec('Command/ReSend', { id }, {}, 'POST')
    } /*删除命令*/,
    Delete(id) {
      return doExec('Command', { id }, {}, 'DELETE')
    } /*查找命令*/,
    Search(condition) {
      return doExec('Command/Search', condition, {}, 'GET')
    }
  },
  CommandTemp: {
    /*获取命令模板*/
    Get(ID) {
      return doExec('CommandTemp', { ID }, {}, 'GET')
    } /*获取命令模板列表*/,
    List() {
      return doExec('CommandTemp/List', {}, {}, 'GET')
    } /*增加命令模板*/,
    Add(form) {
      return doExec('CommandTemp', {}, form, 'POST')
    } /*修改命令模板*/,
    Modify(ID, form) {
      return doExec('CommandTemp', { ID }, form, 'PUT')
    } /*删除命令模板*/,
    Delete(ID) {
      return doExec('CommandTemp', { ID }, {}, 'DELETE')
    }
  },
  Product: {
    /*获取指定产品的信息*/
    Get(id,includes) {
      return doExec('Product', { id,includes }, {}, 'GET')
    } /*注册产品*/,
    Add(form) {
      return doExec('Product', {}, form, 'POST')
    } /*修改产品信息*/,
    Modify(id, form) {
      return doExec('Product', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('Product', { id }, null, 'DELETE')
    } /*查找产品*/,
    Search(condition) {
      return doExec('Product/Search', condition, {}, 'GET')
    } /*搜索超时*/,
    Publish(id,form){
      return doExec('Product/Publish', {}, form, 'POST')
    },
    ListTentacle(id,includes) {
      return doExec('Product/Tentacles', { id,includes }, {}, 'GET')
    },
  },
  Terminal: {
    /*获取指定终端的信息*/
    Get(id,includes) {
      return doExec('Terminal', { id,includes }, {}, 'GET')
    } /*注册终端*/,
    Add(form) {
      return doExec('Terminal', {}, form, 'POST')
    } /*修改终端信息*/,
    Modify(id, form) {
      return doExec('Terminal', { id }, form, 'PUT')
    } /*错误清零*/,
    ResetError(id) {
      return doExec('Terminal/ResetError', { id }, {}, 'POST')
    } /*注销终端*/,
    Delete(id) {
      return doExec('Terminal', { id }, null, 'DELETE')
    } /*查找终端*/,
    Search(condition) {
      return doExec('Terminal/Search', condition, {}, 'GET')
    } /*搜索超时*/,
    SearchTimeout(condition) {
      return doExec('Terminal/SearchTimeout', condition, {}, 'GET')
    },
     /*关注*/
    Focus(id) {
      return doExec('Terminal/Focus', { id }, {}, 'POST')
    } /*取消关注*/,
    CancelFocus(id) {
      return doExec('Terminal/CancelFocus', { id }, {}, 'POST')
    }, /*检查终端编号是否合法*/
    Exists(id) {
      return doExec('Terminal/Exists', { id }, {}, 'POST')
    },
    CanDown(id,includes) {
      return doExec('Terminal/CanDown', { id,includes }, {}, 'GET')
    } /*下发数据*/,
    DownData(id, form) {
      return doExec('Terminal/DownData', { id }, form, 'POST')
    },
    GetValues(id,field,includes){
      return doExec('Terminal/Values', { id,field,includes }, null, 'GET')
    },
    SearchValues(condition){
      return doExec('Terminal/Values/Search', condition, null, 'GET')
    }
  },
  Data: {
    /*数据搜索*/
    Search(condition) {
      return doExec('Data/Search', condition, {}, 'GET')
    },
    ReNotify(condition) {
      return doExec('Data/ReNotify', condition, {}, 'POST')
    }
  },
  
  Analysis: {
    Quota() {
      return doExec('Analysis/Quota', {}, {}, 'GET')
    }
  }
}
