import layout from '@/view/layout';
const menus = [
  {
    path: '',
    redirect: '/home',
    hiddenMenu: true
  },
  {
    path: '/login',
    component: () => import('@/view/account/login'),
    hiddenMenu: true
  },
  { path: '/404', component: () => import('@/view/404'), hidden: true },
  {
    path: '/sqls',
    component: () => import('@/view/sqls'),
    hiddenMenu: true
  },
  {
    path: '/home',
    meta: {
      title: '首页',
      icon: 'home'
    },
    component: layout,
    redirect: '/home/dashboard',
    children: [
      {
        path: 'dashboard',
        meta: {
          title: '今日看板',
          icon: 'dashboard'
        },
        name: 'Dashborad',
        component: () => import('@/view/home/dashboard')
      },
    ]
  },
  
  {
    path: '/product',
    meta: {
      title: '产品管理',
      icon: 'product'
    },
    component: layout,
    redirect: '/product/config',
    children: [
      {
        path: 'config',
        meta: {
          title: '产品配置',
          icon: 'maintance'
        },
        name: 'Config',
        component: () => import('@/view/product/config'),
      },  
      {
        path: 'publish',
        meta: {
          title: '发布终端',
          icon: 'publish'
        },
        name: 'Publish',
        component: () => import('@/view/product/publish'),
      },  
    ]
  },  
  {
    path: '/terminal',
    meta: {
      title: '终端管理',
      icon: 'terminal'
    },
    component: layout,
    redirect: '/terminal/data',
    children: [
      {
        path: 'data',
        meta: {
          title: '数据查询',
          icon: 'data'
        },
        name: 'TerminalData',
        component: () => import('@/view/terminal/data'),
      },
      
      {
        path: 'debug',
        meta: {
          title: '设备调试',
          icon: 'debug'
        },
        name: 'Debug',
        component: () => import('@/view/terminal/debug'),
      },      
      {
        path: 'cmd',
        meta: {
          title: '远程命令',
          icon: 'command'
        },
        name: 'TerminalCmd',
        component: () => import('@/view/terminal/cmd'),
      },
      {
        path: 'msg',
        meta: {
          title: '交易消息',
          icon: 'message'
        },
        name: 'TerminalMsg',
        component: () => import('@/view/terminal/msg'),
      },
      {
        path: 'log',
        meta: {
          title: '终端日志',
          icon: 'log'
        },
        name: 'TerminalLog',
        component: () => import('@/view/terminal/log'),
      }
    ]
  },  
  {
    path: '/secret',
    meta: {
      title: '安全管理',
      icon: 'secret'
    },
    component: layout,
    redirect: '/secret/user',
    children: [
      {
        path: 'user',
        meta: {
          title: '用户管理',
          icon: 'user'
        },
        name: 'user',
        component: () => import('@/view/secret/user')
      },
      {
        path: 'right',
        meta: {
          title: '权限资料',
          icon: 'key'
        },
        name: 'right',
        component: () => import('@/view/secret/right')
      },
      {
        path: 'userRight',
        meta: {
          title: '用户权限',
          icon: 'userright'
        },
        name: 'userRight',
        component: () => import('@/view/secret/userRight')
      }
    ]
  },  
  {
    path: '/setting',
    meta: {
      title: '系统设置',
      icon: 'maintance',
    },
    component: layout,
    redirect: '/setting/log',
    children: [
      {
        path: 'log',
        meta: {
          title: '系统日志',
          icon: 'log'
        },
        name: 'SysLog',
        component: () => import('@/view/setting/log')
      },
      {
        path: 'cmdtemp',
        meta: {
          title: '命令模板',
          icon: 'command'
        },
        name: 'CmdTemp',
        component: () => import('@/view/setting/cmdTemp')
      },  

      {
        path: 'datatype',
        meta: {
          title: '数据类型',
          icon: 'data'
        },
        name: 'DataType',
        component: () => import('@/view/setting/dataType')
      },   
    ]
  },
  { path: '*', redirect: '/404', hiddenMenu: true }
];
export default menus;
