<template>
  <div style="text-align: right;height:100%;">
    <div style="float:left;">
      <img src="/static/logo.png" style="margin: 0 auto;height:60px;padding:4px" />
    </div>
    <div style="float:left;">
      <span style="font-size:26px;line-height:60px;color:#ccc">物联网终端管理平台</span>
    </div>
    <el-dropdown style="margin-right:20px">
      <span class="el-dropdown-link" style="color:#ccc;line-height:60px;">
        {{userName}}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" style="margin-top:-16px">
        <el-dropdown-item @click.native="onChangePass">修改密码</el-dropdown-item>
        <el-dropdown-item divided @click.native="logout">退出系统</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div style="text-align:left">
      <dlgChangePass ref="dlgChangePass" />
    </div>
  </div>
</template>
<script>
import dlgChangePass from "@/view/account/dlgChangePass";
export default {
  computed: {
    userName() {
      return localStorage.getItem("UserName");
    }
  },
  data() {
    return {};
  },
  methods: {
    onChangePass() {
      this.$refs.dlgChangePass.open();
    },
    logout() {
      this.$secret
        .logout()
        .then(() => {
          this.$router.replace({ path: "/Login" });
        })
        .catch(() => {
          this.$router.replace({ path: "/Login" });
        });
      this.$router.replace({ path: "/Login" });
    }
  },
  components: { dlgChangePass }
};
</script>

