
export const newGuid = () => {
    let guid = "";
    for (let i = 1; i <= 32; i++) {
        let n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
            guid += "-";
    }
    return guid;
};

export const showObj = (e) => {
    let ret = getObjDesc(e);
    alert(ret);
};
const getObjDesc = (e) => {
    if (!e)
        return '空';
    let ret = "Properties:\n";
    for (var prop in e) {
        let val = e[prop];
        let t = typeof (val);
        if (t == 'object') {
            ret += getObjDesc(val);
        }
        else if (t === "function") {
            ret += (prop + "()");
        }
        else {
            ret += prop + ": " + val;
        }
        ret += ";\n";
    }
    return ret;
}
export const hexToString = (s) => {
    var r = "0x";
    var hexes = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f");
    for (var i = 0; i < s.length; i++) {
        r += hexes[s.charCodeAt(i) >> 4] + hexes[s.charCodeAt(i) & 0xf];
    }
    return r;
}

export const stringToHex = (h) => {
    var r = "";
    for (var i = (h.substr(0, 2) == "0x") ? 2 : 0; i < h.length; i += 2) {
        r += String.fromCharCode(parseInt(h.substr(i, 2), 16));
    }
    return r;
}
