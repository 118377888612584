export default {
    inserted(el, binding) {
        let { value, arg,modifiers } = binding
        let margin = 1
        if (value) margin = value
        if(arg==null){
            el.style['margin-left'] = margin+'px'
            el.style['margin-right'] = margin+'px'
        } else if(arg==='all')
            el.style['margin'] = margin+'px'
        else    
            el.style['margin-'+arg]=margin+'px'

    }
}
