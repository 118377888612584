<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive :include="cachedViews">
      <router-view style="overflow:hidden auto;padding:10px;"></router-view>
    </keep-alive>
  </transition>
</template>
<script>
export default {
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.fullPath
    }
  }
}
</script>



