import CryptoJS from 'crypto-js'

const DES = {
    encrypt(text, key) {
        var keyHex = CryptoJS.enc.Utf8.parse(key);
        //var ivHex = CryptoJS.enc.Utf8.parse('heshengj');
        let c = CryptoJS.DES.encrypt(text, keyHex,
            {
                //iv: ivHex,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
        return c.toString();
    },
    decrypt(text, key) {
        try {
            if (key == null || key == undefined)
                return '';
            var keyHex = CryptoJS.enc.Utf8.parse(key);
            //var ivHex = CryptoJS.enc.Utf8.parse('heshengj');
            let c = CryptoJS.DES.decrypt(text, keyHex,
                {
                    //iv: ivHex,
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                });
            return c.toString(CryptoJS.enc.Utf8);
        }
        catch(e){
            console.log('异常发生');
            console.log(e);
            return '';
        }
    }
}

export default DES;
