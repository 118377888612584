// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill';
import Vue from 'vue';
import '../theme/index.css';
import ElementUI from 'element-ui';
import '@/styles/index.scss' // global css
import '@/styles/flex.css'
import Router from 'vue-router';
import routes from './router.js';
import App from './App';
import store from './store'
import directives from '@/directives'
Object.keys(directives).forEach(key => {
  Vue.directive(key,directives[key])
});
import ServerMixin from './mixin/ServerMixin';
import './icons';

Vue.use(Router);
Vue.use(ElementUI,{size: 'mini'});
Vue.use(ServerMixin);

const router = new Router({ routes });
var vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
export default vue;
